<template>
  <div id="vip">
    <div class="admin_main_block">
      <div class="user_temp_title">价格列表</div>
      <div class="admin_table_main">
        <div class="vip_type2">
          *本价格具有一定实时性，随快递价格会有一定范围波动。
        </div>
        <table
        border="0"
        align="center"
        cellpadding="0"
        cellspacing="0"
        v-loading="loading"
      >
        <tbody>
        <tr >
          <td>
            仓库名称
          </td>
          <td>
            快递价格
          </td>
          <td>
            我的价格
          </td>
          <tr v-for="(item, key) in storeList">
            <td>
              {{item.name}}-【{{item.expressName}}】
            </td>
            <td>
              {{item.defaultSiteRealSellPrice}}
            </td>
            <td>
              {{item.siteRealSellPrice}}
            </td>
          </tr>
      </tbody>
    </table>



        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      storeList: [],
      sroreId: "",
      expressSheetList: [],
      expressSheetId: "",
      expressFee: [],
      vipList: [], //等级列表
      vipList2: [],
      expressSheetNameList: [
        "其他面单",
        "通用面单",
        "菜鸟面单",
        "拼多多面单",
        "京东面单",
      ],
      nowVip: "", //当前vip等级
      loading: false, //防重复点击
      aboutShow: 1,
    };
  },
  created() {
    this.getStoreList();
    if ("www.fabwang.com;fab.fabwang.com".indexOf(window.location.host) != -1) {
      this.aboutShow = 2;
    }
  },
  mounted() {},
  watch: {},
  methods: {
    copy() {
      this.$request.post({
        url: "/ReportApi/business/getInviteCode",
        params: { id: localStorage.getItem("ids") },
        success: (res) => {
          var inviteUrl =
            window.location.protocol +
            "//" +
            window.location.host +
            "/?inviteCode=" +
            res;
          this.$common.copy(inviteUrl);
        },
        finally: () => {},
      });
    },
    recharge() {
      this.$router.push("/member/recharge");
    },

    getStoreList() {
      this.$request.post({
        url: "/ReportApi/business/goodsStore/getStoreInfo",
        params: {},
        success: (res) => {
          this.storeList = res;
          if (this.storeList.length > 0) {
            this.sroreId = this.storeList[0].id;
            this.getExpressSheet(this.sroreId);
          }
        },
      });
    },

    getExpressSheet(storeId) {
      this.$request.post({
        url: "/ReportApi/business/expressSheet/expressSheet",
        params: {
          storeId: storeId,
        },
        success: (res) => {
          this.expressSheetList = res;
          if (this.expressSheetList.length > 0) {
            this.expressSheetId = this.expressSheetList[0].id;
            this.getVipLevel(this.expressSheetId);
          }
        },
      });
    },

    getVipLevel(expressSheetId) {
      this.loading = true;
      this.$request.post({
        url: "/ReportApi/business/expressFree/getExpressFree",
        params: {
          expressSheetId: expressSheetId,
          tenantId: this.$store.state.userInfo.info.tenantId,
          level: this.$store.state.userInfo.level,
        },
        success: (res) => {

          this.expressFee = res.expressFee;
          this.vipList = res.vipList;
          if (!this.nowVip) {
            var i = 1;
            res.vipList.forEach((item) => {
              if (this.$store.state.userInfo.info.levelId === item.id) {
                i = 2;
                this.nowVip = item;
              }
            });
            if (i == 1) {

            }
          }
        },
        finally: () => {
          this.loading = false;
        },
      });
    },














  },
};
</script>

<style lang="scss" scoped>
#vip {
  .admin_main_block {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    min-height: 855px;
    border-radius: 5px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .admin_table_main {
      .vip_type {
        margin-left: 50px;
        line-height: 60px;
        font-size: 18px;
      }
      .vip_type2 {
        margin-left: 50px;
        line-height: 20px;
        font-size: 14px;
        color: #ea242f;
        margin-bottom: 10px;
      }
      .storage_vip_list {
        margin-left: 50px;
        margin-bottom: 10px;
        span {
          padding: 0 10px;
          min-width: 165px;
          line-height: 40px;
          border: 1px solid #dedede;
          border-radius: 5px;
          margin-right: 10px;
          margin-bottom: 5px;
          display: block;
          height: 40px;
          text-align: center;
          color: #666;
          float: left;
          cursor: pointer;
          &.red {
            color: white;
            background-color: #ea242f;
            border-color: #ea242f;
          }
        }
      }
      table {
        margin: 10px auto 0;
        font-size: 14px;
        width: 880px;
        color: rgb(119, 119, 119);
        border-left: 1px solid rgb(222, 222, 222);
        border-top: 1px solid rgb(222, 222, 222);
        tbody {

          tr {
            &:first-child {
              background: #f2f6fc;
              color: #626262;
              td {
                border-right: none;
                height: 35px;
                &:nth-child(1) {
                  border-right: 1px solid rgb(222, 222, 222);
                }
                &:nth-child(3) {
                  border: 1px solid  #ea242f;
                  color: white;
                  background: #ff4642;
                }
              }
            }
            td {
              height: 50px;
              width: 60px;
              text-align: center;
              border-bottom: 1px solid rgb(222, 222, 222);
              border-right: 1px solid rgb(222, 222, 222);
               &:nth-child(3){
                 border-bottom: 1px solid  #ea242f;
                 border-right: 1px solid  #ea242f;
                 border-left: 1px solid  #ea242f;
                   color: #ea242f;
               }
            }
          }
        }
      }
      .lastdiv {
        margin-left: 50px;
        background: #ea242f;
        color: white;
        line-height: 40px;
        text-align: center;
        width: 880px;
        border-radius: 5px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
